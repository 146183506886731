import { db } from "../../../../firebase/Firebase";
import { fetchAllUser } from "../../content/redux/adminAction";

export const adminDeleteUser = () => (dispatch) => {
  // require cloud function to delete auth user
  // must delete from user IPC and cause auth to delete user
  // export const removeUser = functions.firestore.document("/users/{uid}")
  //   .onDelete((snapshot, context) => {
  //       const serviceAccount = require('path/to/serviceAccountKey.json');
  //       admin.initializeApp({
  //           credential: admin.credential.cert(serviceAccount),
  //           databaseURL: "https://<DATABASE_NAME>>.firebaseio.com"
  //       });
  //       return admin.auth().deleteUser(context.params.uid);
  //   });
};

export const adminDeleteUserCourse = (deleteCourseList, UserData) => (
  dispatch
) => {
  const userId = UserData.userId;
  const userUnit = { ...UserData.covid.unit };
  console.log("UserData", UserData);

  deleteCourseList.forEach((item) => {
    delete userUnit[item];
  });
  console.log("userUnit2 ", userUnit);

  db.collection("usersIPC")
    .doc(userId)
    .update({
      "covid.unit": userUnit,
    })
    .then(() => {
      console.log(
        `%c DB: ${
          UserData.displayName
        }, courses is deleted ${deleteCourseList.toString()}`,
        "color: #3373FF"
      );
    })
    .then(() => {
      dispatch(fetchAllUser());
    })
    .catch((error) => {
      console.log("unable to delete course", error);
    });
};
