import { FETCH_USER_COORDINATORS } from "./coordinatorPanelActions";

const initialState = {
  userCoordinator: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_COORDINATORS:
      return { ...state, userCoordinator: payload };

    default:
      return state;
  }
};
