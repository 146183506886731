import React, { useEffect } from "react";
import "./style/App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/others/ScrollToTop";
import Nav from "./components/nav/Nav";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./redux/action/userAction";
import {
  fetchCoord,
  fetchRole,
  fetchPosition,
  fetchLocation,
  fetchCoordList,
} from "./redux/action/publicAction";
// import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Account from "./pages/account/Account";
import Home from "./pages/home/Home";
import Slide from "./pages/slide/Slide";
import Courses from "./pages/courses/Course";
import About from "./pages/about/About";
import EditAccount from "./pages/account/EditAccount";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
import AdminEditAccount from "./pages/admin/user/AdminEditAccount";
import AdminContentUnitAddNew from "./pages/admin/content/AdminContentUnitAddNew";
import Coordinator from "./pages/coordinator/Coordinator";
import CovidListEdit from "./pages/admin/content/CovidListEdit";
import Finish from "./pages/finish/Finish";
import { theme as styleTheme } from "./style/style";
import ContainerX from "./components/ContainerX";
import { ReactQueryDevtools } from "react-query-devtools";
import AdminContentUnitEdit from "./pages/admin/content/AdminContentUnitEdit";
import Setup from "./pages/setup/Setup";
import ProtectedRoutes from "./components/ProtectedRoutes";
import RouterDefault from "./pages/routerDefault/RouterDefault";
import MaxWidthContainer from "./components/MaxWidthContainer";

// import "./firebaseui-styling.global.css"; // Import globally.

// automatic responsive font sizes based on variant
// https://material-ui.com/customization/typography/

// const useStyles = makeStyles((theme) => ({
//   normalLinks: {
//     maxWidth: 700,
//     marginLeft: "auto",
//     marginRight: "auto",

//     [theme.breakpoints.only("xs")]: {
//       maxWidth: 400,
//     },
//   },
// }));

function App() {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const authenticated = useSelector((state) => state.authData.authenticated);
  console.log("authenticated", authenticated);

  useEffect(() => {
    dispatch(fetchCoord());
    dispatch(fetchRole());
    dispatch(fetchPosition());
    dispatch(fetchLocation());
    dispatch(fetchCoordList());
  }, [dispatch]);

  return (
    <Router>
      <ThemeProvider theme={styleTheme}>
        <Nav />
        <ScrollToTop>
          <Switch>
            <Route exact path='/'>
              <MaxWidthContainer normalWidth>
                <ContainerX showNavBtm>
                  <Home />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/login'>
              <MaxWidthContainer normalWidth>
                <ContainerX showNavBtm>
                  <Login />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/slide/:topic/:info/:slidenum?'>
              <MaxWidthContainer normalWidth>
                <ContainerX>
                  <Slide />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/account'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer normalWidth>
                  <ContainerX showNavBtm>
                    <Account />
                  </ContainerX>
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route path='/accountEdit'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer normalWidth>
                  <ContainerX showNavBtm>
                    <EditAccount />
                  </ContainerX>
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route path='/courses'>
              <MaxWidthContainer normalWidth>
                <ContainerX showNavBtm>
                  <Courses />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/about'>
              <MaxWidthContainer normalWidth>
                <ContainerX showNavBtm>
                  <About />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/finish'>
              <MaxWidthContainer normalWidth>
                <ContainerX>
                  <Finish />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
            <Route path='/setup'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer normalWidth>
                  <ContainerX>
                    <Setup />
                  </ContainerX>
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route path='/admin'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer largeWidth>
                  <Admin />
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route path='/coordinator'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer largeWidth>
                  <Coordinator />
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route
              path='/admineditaccount/:userId'
              render={(props) => (
                <ProtectedRoutes auth={authenticated}>
                  <MaxWidthContainer largeWidth>
                    <AdminEditAccount {...props} />
                  </MaxWidthContainer>
                </ProtectedRoutes>
              )}
            />
            <Route path='/admincontentunitaddnew'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer largeWidth>
                  <AdminContentUnitAddNew />
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>

            <Route path='/admincontentunitedit/:topic/:info/'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer largeWidth>
                  <AdminContentUnitEdit />
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route path='/covidlistedit'>
              <ProtectedRoutes auth={authenticated}>
                <MaxWidthContainer largeWidth>
                  <CovidListEdit />
                </MaxWidthContainer>
              </ProtectedRoutes>
            </Route>
            <Route>
              <MaxWidthContainer largeWidth>
                <ContainerX>
                  <RouterDefault />
                </ContainerX>
              </MaxWidthContainer>
            </Route>
          </Switch>
        </ScrollToTop>
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userData,
    showNavBtm: state.publicData.showNavBtm,
  };
};

const mapDispatchToProps = {
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
