import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { editUserData } from "../../redux/action/userAction";

import { useForm } from "react-hook-form";
import { TextField, Box, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import {
  fetchCoordList,
  fetchLocationList,
  fetchPosition,
} from "../../redux/action";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import ValueSelector from "../../components/selector/ValueSelector";
import {
  addCoordIdToDataBeforeSubmit,
  mappingValueToFitSelector,
} from "../../components/selector/extraFunction";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  containerBox: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    textAlign: "left",
    width: "100%",
  },
  menuItem: {
    textAlign: "left",
  },
  buttonBox: {
    marginTop: 30,
  },
});
const EditAccount = ({
  fetchPosition,
  fetchCoordList,
  fetchLocationList,
  mapLocation,
  mapCoord,
  mapPosition,
  UserData,
  editUserData,
  coordList,
}) => {
  const classes = useStyles();
  let history = useHistory();
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = (data) => {
    const newDataWithCoordId = addCoordIdToDataBeforeSubmit(data, coordList);

    editUserData(newDataWithCoordId);
    history.push("/account");

    return console.log("data submit", data);
  };

  useEffect(() => {
    fetchPosition();
    fetchCoordList();
    fetchLocationList();
  }, [fetchPosition, fetchLocationList, fetchCoordList]);

  // will not return anything if user is not populated
  if (!UserData.userId) {
    return null;
  }

  return (
    <div>
      <TitleHeader title='EDIT ACCOUNT' />

      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className={classes.containerBox}>
          <Box pt={4}>
            <TextField
              className={classes.field}
              id='outlined-required'
              label='Email'
              defaultValue={UserData.email}
              variant='outlined'
              disabled
            />
          </Box>

          <Box pt={4}>
            <TextField
              className={classes.field}
              required
              color='secondary'
              id='outlined-required'
              label='Name'
              defaultValue={UserData.displayName}
              variant='outlined'
              name='displayName'
              inputRef={register({ required: true })}
            />
            <Box>{errors.displayName && "Name is required"}</Box>
          </Box>
          <Box p={1}>
            {mapLocation.length && (
              <Box mt={3}>
                <ValueSelector
                  userData={UserData.location}
                  name='location'
                  control={control}
                  label='Location'
                  dataMap={mapLocation}
                  defaultValue={UserData.location}
                />
              </Box>
            )}
            {mapPosition.length && (
              <Box mt={3}>
                <ValueSelector
                  userData={UserData.position}
                  name='position'
                  control={control}
                  label='Position'
                  dataMap={mapPosition}
                  defaultValue={UserData.position}
                />
              </Box>
            )}
            {mapCoord.length && (
              <Box mt={3}>
                <ValueSelector
                  userData={UserData.coordinator}
                  name='coordinator'
                  control={control}
                  label='Coordinator'
                  dataMap={mapCoord}
                  defaultValue={UserData.coordinator}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Grid container justify='space-evenly'>
            <Button
              component={Link}
              color='secondary'
              variant='outlined'
              to='/account'
            >
              Cancel
            </Button>
            <Button color='secondary' variant='outlined' type='submit'>
              Save
            </Button>
          </Grid>
        </Box>
      </form>
    </div>
  );
};
const mapStateToProps = (state) => {
  const location = state.publicData.locationList;
  const coord = state.publicData.coordList;
  const position = state.publicData.position;

  const mapLocation = mappingValueToFitSelector(location);
  const mapCoord = mappingValueToFitSelector(coord, "coord");
  const mapPosition = mappingValueToFitSelector(position);

  return {
    UserData: state.userData,
    mapLocation,
    mapCoord,
    mapPosition,
    coordList: coord,
  };
};

const mapDispatchToProps = {
  editUserData,
  fetchPosition,
  fetchCoordList,
  fetchLocationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);
