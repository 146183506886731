import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  normalWidth: {
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",

    [theme.breakpoints.only("xs")]: {
      maxWidth: 400,
    },
  },
  largeWidth: {
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",

    [theme.breakpoints.only("xs")]: {
      maxWidth: 400,
    },
  },
}));

const MaxWidthContainer = ({ normalWidth, largeWidth, children }) => {
  const classes = useStyles();

  const normalWidthDisplay = (
    <div className={classes.normalWidth}> {children}</div>
  );

  const largeWidthDisplay = (
    <div className={classes.largeWidth}> {children}</div>
  );

  let display;

  if (normalWidth) {
    display = normalWidthDisplay;
  } else if (largeWidth) {
    display = largeWidthDisplay;
  } else display = null;

  return display;
};

export default MaxWidthContainer;
