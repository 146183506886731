import React from "react";
import { Paper, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  covidList: {
    paddingLeft: 10,
  },
  button: {
    marginTop: 10,
    marginBottom: 5,
  },
});
const AdminContentCovidList = ({ covidListUnitOrder, covidList }) => {
  const classes = useStyles();

  const covidListMap =
    covidListUnitOrder &&
    Object.values(covidListUnitOrder).map((item) => (
      <div key={item.info}>
        Unit: {item.unit} - Public: {item.public} - Info: {item.info}
      </div>
    ));

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Typography variant='h6'>CovidList</Typography>
        <Typography>Info: {covidList.info}</Typography>
        <Typography>Title: {covidList.title}</Typography>
        <Typography>Topic: {covidList.topic}</Typography>
        <div className={classes.covidList}>{covidListMap}</div>
        <Button
          variant='outlined'
          color='secondary'
          component={Link}
          to='/covidlistedit'
          size='small'
          className={classes.button}
        >
          Edit CovidList
        </Button>
      </Box>
    </Paper>
  );
};

export default AdminContentCovidList;
