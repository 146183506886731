import React from "react";
import { Box, Typography, Button, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navButtonSelection } from "../../redux/action/navAction";
import PropTypes from "prop-types";
import { data } from "../../version";
// import { ReactComponent as SvgImport } from "../../svg-loaders/bars.svg";
// import { TailSpin } from "svg-loaders-react";

const useStyles = makeStyles((theme) => ({
  textBox: {
    backgroundColor: "whitesmoke",
  },
  title: {
    fontWeight: 800,
  },
  viewCourse: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.only("xs")]: {
      marginTop: 10,
      marginBottom: 0,
    },
  },
  firstCourse: {
    marginTop: 40,
    marginBottom: 30,
    [theme.breakpoints.only("xs")]: {
      marginTop: 20,
      marginBottom: 0,
    },
  },
}));

const Home = ({ navButtonSelection }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div data-test='home-page'>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign='center' my={3}>
              <Typography variant='h3' className={classes.title}>
                ONE IPC
              </Typography>
              <Typography variant='body1'>{data.version}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign='center' my={2}>
              <Typography variant='h5'>
                Advance Infection Control & Prevention Education
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              align='center'
              data-test='course-button'
              className={classes.viewCourse}
            >
              <Button
                variant='contained'
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  color: "white",
                }}
                component={Link}
                to='/courses'
                onClick={() => navButtonSelection(1)}
              >
                VIEW COURSES
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
                <Box p={2} className={classes.textBox}>
                  <Typography>
                    Up-to-date COVID infection control & prevention education
                    designed for everyday life.
                  </Typography>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
                <Box p={2} className={classes.textBox}>
                  <Typography>
                    Courses were develop in collaboration with public health and
                    medical professionals.
                  </Typography>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
                <Box p={2} className={classes.textBox}>
                  <Typography>
                    Try the free COVID-19 courses today! Login to save your
                    progress.
                  </Typography>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} textAlign='center' className={classes.firstCourse}>
              <Button
                variant='contained'
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  color: "white",
                }}
                component={Link}
                to='/slide/covid/OVERVIEW/'
                onClick={() => navButtonSelection(1)}
              >
                COVID-19 OVERVIEW
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { navButtonSelection };

Home.propTypes = {
  navButtonSelection: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
