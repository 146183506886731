import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Box } from "@material-ui/core";
import { Controller } from "react-hook-form";
import useValueSelector from "./useValueSelector";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
});

const ValueSelector = ({
  name,
  label,
  dataMap,
  control,
  userData,
  defaultValue = "",
}) => {
  const classes = useStyles()

  const [selectValues, handleChange] = useValueSelector(userData);

  return (
    <Box>
      <Controller
        name={`${name}`}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: true }}
        as={
          <TextField
            size='small'
            className={classes.field}
            id='select-filter-category'
            select
            label={`${label}`}
            value={selectValues}
            onChange={handleChange}
            color='secondary'
          >
            {dataMap.map((item, index) => (
              <MenuItem key={item + index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        }
      />
    </Box>
  );
};

export default ValueSelector;
