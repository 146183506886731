import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

const UserDataCoordinatorsList = ({ userCoordinatorData }) => {
  console.log("userCoordinatorData", userCoordinatorData);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant='h6'>
                  User data fetched coordinator's list names
                </Typography>
              </TableCell>
              <TableCell align='right'>email</TableCell>
              <TableCell align='right'>coordId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userCoordinatorData.map((item) => (
              <TableRow key={item.userId}>
                <TableCell component='th' scope='row'>
                  {item.displayName}
                </TableCell>
                <TableCell align='right'>{item.email}</TableCell>
                <TableCell align='right'>{item.userId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserDataCoordinatorsList;
