import { useState } from "react";

export const useValueSelector = (initialValues) => {
  const [selectValues, setSelectValues] = useState(initialValues);

  const handleChange = (event) => {
    setSelectValues(event.target.value);
  };
  return [selectValues, handleChange];
};

export default useValueSelector;
