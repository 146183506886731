export const addCoordIdToDataBeforeSubmit = (data, coordList) => {


  const coordName = data.coordinator;
  const coordListFiltered = coordList.filter((item) => {
    return coordName === item.name;
  });
  return { ...data, coordId: coordListFiltered[0].coordId };
};

// second paramater is used to swtich return value to get the name
export const mappingValueToFitSelector = (mapData, useCoord) => {
  const mappedData = Object.values(mapData).map((item) => {
    if (useCoord === "coord") {
      return { value: item.name, label: item.name };
    }
    return { value: item, label: item };
  });
  return mappedData;
};
