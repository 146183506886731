import { db } from "../../firebase/Firebase";

export const FETCH_COORDINATOR_USER = "FETCH_COORDINATOR_USER";

export const fetchCoordinatorUser = (coordId) => async (dispatch) => {
  const CoordinatorUserList = [];
  try {
    const userQuerySnapShot = await db
      .collection("usersIPC")
      .where("coordId", "==", coordId)
      .get();
    console.log(`%c DB:fetchCoordinatorUser`, "color: #3373FF");

    userQuerySnapShot.forEach((doc) => {
      CoordinatorUserList.push(doc.data());
    });
    dispatch({ type: FETCH_COORDINATOR_USER, payload: CoordinatorUserList });
  } catch (error) {
    console.log("userQuerySnapShot for coordinator's user does not exist");

    console.log(
      `%c DB:fetchCoordinatorUser error`,
      "color: 	#FF0000",
      "coordinator's user:" + error
    );
  }
};
