import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";
import AdminUser from "./user/AdminUser";
import AdminContent from "./content/AdminContent";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import { fetchAllUser } from "./content/redux/adminAction";
import CoordinatorPanel from "./coordinatorPanel/CoordinatorPanel";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchField: {
    marginBottom: 20,
    marginRight: 10,
  },
  button: {
    marginRight: 10,
    marginTop: 10,
  },
  boxContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
});

const Admin = () => {
  const classes = useStyles();
  let { url, path } = useRouteMatch();

  return (
    <div>
      <Box mt={5}>
        <TitleHeader title='ADMIN' />
      </Box>
      <Box m={2}>
        <Button
          className={classes.button}
          variant='outlined'
          color='secondary'
          component={RouterLink}
          to={`${url}/user`}
        >
          USERS
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          color='secondary'
          component={RouterLink}
          to={`${url}/content`}
        >
          CONTENT
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          color='secondary'
          component={RouterLink}
          to={`${url}/coordinatorpanel`}
        >
          COORDINATOR PANEL
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          color='secondary'
          component={RouterLink}
          to={`/account`}
        >
          ACCOUNT PAGE
        </Button>
      </Box>
      <Box m={2}>
        <Switch>
          <Route exact path={path}>
            Select A Link
          </Route>
          <Route path={`${path}/user`}>
            <AdminUser />
          </Route>
          <Route path={`${path}/content`}>
            <AdminContent />
          </Route>
          <Route path={`${path}/coordinatorpanel`}>
            <CoordinatorPanel />
          </Route>
        </Switch>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  const allUser = state.adminData.allUser;

  return {
    allUser,
  };
};

const mapDispatchToProps = {
  fetchAllUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
