import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { editUserData } from "../../../redux/action/userAction";
import { useForm } from "react-hook-form";
import { TextField, Box, Button, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

import AdminInfoTable from "./AdminInfoTable";

import TitleHeader from "../../../components/titleHeader/TitleHeader";
import ValueSelector from "../../../components/selector/ValueSelector";

import {
  fetchCoordList,
  fetchLocationList,
} from "../../../redux/action/publicAction";
import {
  addCoordIdToDataBeforeSubmit,
  mappingValueToFitSelector,
} from "../../../components/selector/extraFunction";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 20,
  },
  containerBox: {
    maxWidth: 680,
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const AdminEditAccount = ({
  mapLocation,
  mapRole,
  mapCoord,
  mapPosition,
  editUserData,
  filteredUserData,
  UserData,
  fetchCoordList,
  fetchLocationList,
  coordList,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();

  useEffect(() => {
    fetchCoordList();
    fetchLocationList();
  }, [fetchCoordList, fetchLocationList]);

  const onSubmit = (data) => {
    const newDataWithCoordId = addCoordIdToDataBeforeSubmit(data, coordList);

    editUserData(newDataWithCoordId, UserData.userId);
    return console.log("submitted data", newDataWithCoordId);
  };

  return (
    <div>
      <TitleHeader title='ADMIN EDIT ACCOUNT' />
      <AdminInfoTable filteredUserData={filteredUserData} UserData={UserData} />
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box component={Paper} className={classes.containerBox}>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              id='outlined-required'
              label='Email'
              defaultValue={UserData.email}
              disabled
              color='secondary'
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              id='outlined-required'
              label='Name'
              defaultValue={UserData.displayName}
              name='displayName'
              inputRef={register({ required: true })}
              color='secondary'
            />
            <Box>{errors.displayName && "Name is required"}</Box>
          </Box>

          {mapLocation.length && (
            <ValueSelector
              userData={UserData.location}
              name='location'
              control={control}
              label='Location'
              dataMap={mapLocation}
              defaultValue={UserData.location}
            />
          )}

          {mapPosition.length && (
            <ValueSelector
              userData={UserData.position}
              name='position'
              control={control}
              label='Position'
              dataMap={mapPosition}
              defaultValue={UserData.position}
            />
          )}

          {mapCoord.length && (
            <ValueSelector
              userData={UserData.coordinator}
              name='coordinator'
              control={control}
              label='Coordinator'
              dataMap={mapCoord}
              defaultValue={UserData.coordinator}
            />
          )}

          {mapRole.length && (
            <ValueSelector
              userData={UserData.role}
              name='role'
              control={control}
              label='Role'
              dataMap={mapRole}
              defaultValue={UserData.role}
            />
          )}
        </Box>

        <Box className={classes.buttonBox}>
          <Button
            component={Link}
            color='secondary'
            variant='outlined'
            to='/admin'
            className={classes.MR}
          >
            Cancel
          </Button>
          <Button color='secondary' variant='outlined' type='submit'>
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  const ownPropUserId = ownProps.match.params.userId;

  const allUser = state.adminData.allUser;
  const location = state.publicData.locationList;
  const coord = state.publicData.coordList;
  const role = state.publicData.role;
  const position = state.publicData.position;

  // get all the user data and filter out that user data
  // that matches the userId paramater from the link
  const UserData = allUser.filter((item) => {
    return item.userId === ownPropUserId;
  })[0];

  const filteredUserData = Object.values(UserData.covid.unit);

  //create an object array out of fetched data
  const mapLocation = mappingValueToFitSelector(location);
  const mapRole = mappingValueToFitSelector(role);
  const mapCoord = mappingValueToFitSelector(coord, "coord");
  const mapPosition = mappingValueToFitSelector(position);

  // console.log("mappedPosition", mappedPosition);

  return {
    filteredUserData,
    mapLocation,
    UserData,
    mapRole,
    mapCoord,
    mapPosition,
    coordList: coord,
  };
};

const mapDispatchToProps = {
  editUserData,
  fetchCoordList,
  fetchLocationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditAccount);
