import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  fetchUserCoordinators,
  submitNewCoordList,
  deleteUserFromCoordList,
} from "./redux/coordinatorPanelActions";
import ClearIcon from "@material-ui/icons/Clear";
import UserDataCoordinatorsList from "./UserDataCoordinatorsList";
import { useForm } from "react-hook-form";
import AlertDialog from "../../../components/others/AlertDialog";

const CoordinatorPanel = ({
  fetchUserCoordinators,
  userDataCoordinator,
  submitNewCoordList,
  coordList,
  deleteUserFromCoordList,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [AddCoord, setAddCoord] = useState(false);

  const onSubmit = (data) => {
    const submissionData = [...coordList, data];
    setAddCoord(false);

    submitNewCoordList(submissionData);
    console.log(data);
    console.log("submissionData", submissionData);
  };

  const addButton = AddCoord ? (
    <>
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' justifyContent='space-around' flexDirection='row'>
          <Box>
            <TextField
              size='small'
              id='outlined-basic'
              label='name'
              variant='outlined'
              color='secondary'
              inputRef={register({ required: true })}
              name='name'
              required
              error={!!errors.name}
            />
            <Box>{errors.name && "Name is required"}</Box>
          </Box>
          <Box>
            <TextField
              size='small'
              id='outlined-basic'
              label='coordId'
              variant='outlined'
              color='secondary'
              inputRef={register({ required: true })}
              name='coordId'
              required
              error={!!errors.name}
            />
            <Box>{errors.coordId && "CoordId is required"}</Box>
          </Box>
          <Box>
            <Button variant='outlined' color='secondary' type='submit'>
              Submit
            </Button>
          </Box>
          <Box>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setAddCoord(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  ) : (
    <Box textAlign='right'>
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => setAddCoord(true)}
      >
        Add
      </Button>
    </Box>
  );

  return (
    <>
      <Box my={2}>
        <Button
          color='secondary'
          variant='outlined'
          onClick={fetchUserCoordinators}
        >
          Fetch User Coordinator's List
        </Button>
      </Box>

      {userDataCoordinator.length > 0 && (
        <UserDataCoordinatorsList userCoordinatorData={userDataCoordinator} />
      )}

      <Box my={2}>
        {Object.keys(coordList).length > 0 && (
          <>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='h6'>
                        Coordinator's list names
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>id</TableCell>
                    <TableCell align='right'>delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coordList.map((item, index) => (
                    <TableRow key={item.name + index}>
                      <TableCell component='th' scope='row'>
                        {item.name}
                      </TableCell>
                      <TableCell align='right'>{item.coordId}</TableCell>
                      <TableCell align='right'>
                        <AlertDialog
                          alertButtonText={<ClearIcon />}
                          alertDialogTitle='Delete Account Confirmation'
                          alertDialogDescription='Are you sure you want to delete this coord?'
                          handleCancelButtonText='Cancel'
                          handleConfirmButtonText='Delete'
                          confirmColor='secondary'
                          confirmActionFunction={() =>
                            deleteUserFromCoordList(item.coordId)
                          }
                          btnVariant={"text"}
                          disabled={false}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Box my={2}>{addButton}</Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  userDataCoordinator: state.coordinatorPanelData.userCoordinator,
  coordList: state.publicData.coordList,
});

const mapDispatchToProps = {
  fetchUserCoordinators,
  submitNewCoordList,
  deleteUserFromCoordList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPanel);
