import { db } from "../../../../firebase/Firebase";
import { fetchCoordList } from "../../../../redux/action/publicAction";
export const FETCH_USER_COORDINATORS = "FETCH_USER_COORDINATORS";
export const FETCH_COORDLIST = "FETCH_COORDLIST";

export const fetchUserCoordinators = () => async (dispatch, getState) => {
  const userCoordListData = getState().coordinatorPanelData.userCoordinator;
  console.log("userCoordListData", userCoordListData);

  if (userCoordListData.length > 0) {
    return console.log(
      "userCoordListData already populated",
      userCoordListData
    );
  }

  const allCoordinatorsList = [];
  try {
    const userQuerySnapShot = await db
      .collection("usersIPC")
      .where("role", "==", "coordinator")
      .get();

    userQuerySnapShot.forEach((doc) => {
      allCoordinatorsList.push(doc.data());
    });

    dispatch({ type: FETCH_USER_COORDINATORS, payload: allCoordinatorsList });
    console.log(`%c DB:Fetch All User From Coordinator`, "color: #3373FF");
  } catch (error) {
    console.log("fetchUserCoordinators does not exist", error);
  }
};

export const submitNewCoordList = (submittedData) => async (dispatch) => {
  console.log("submittedData", submittedData);

  try {
    await db
      .collection("covid")
      .doc("coordList")
      .update({ list: submittedData });

    // set fetch by pass to true to escape coordList not fetching if coordList already populated
    dispatch(fetchCoordList(true));

    console.log(`%c DB:Submit New CoordList Successful`, "color: #3373FF");
  } catch (error) {
    console.log("submitNewCoordList failed", error);
  }
};

export const deleteUserFromCoordList = (coordId) => async (
  dispatch,
  getState
) => {
  const coordList = getState().publicData.coordList;

  const createNewCoordListWithoutDeletedId = coordList.filter((item) => {
    return item.coordId !== coordId;
  });

  console.log(
    "createNewCoordListWithoutDeletedId",
    createNewCoordListWithoutDeletedId
  );

  try {
    await db
      .collection("covid")
      .doc("coordList")
      .update({ list: createNewCoordListWithoutDeletedId });

    // set forceFetch to true to escape coordList not fetching if coordList already populated
    dispatch(fetchCoordList(true));

    console.log(
      `%c DB:Delete User From CoordList Successful`,
      "color: #3373FF"
    );
  } catch (error) {
    console.log("deleteUserFromCoordList failed", error);
  }
};
