import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Button, IconButton } from "@material-ui/core";
import { adminDeleteUserCourse } from "./redux/action";
import { connect } from "react-redux";
import { fetchUserData } from "../../../redux/action/userAction";

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  },
  btn: { marginRight: 10 },
});

const AdminInfoTable = ({
  filteredUserData,
  adminDeleteUserCourse,
  UserData,
}) => {
  const [edit, setEdit] = useState(false);
  const [deleteCourseList, setDeleteCourseList] = useState([]);

  const classes = useStyles();

  console.log("filteredUserData", filteredUserData);
  console.log("deleteCourseList", deleteCourseList);

  const handleCancel = () => {
    setEdit(false);
    setDeleteCourseList([]);
  };

  const handleDelete = () => {
    adminDeleteUserCourse(deleteCourseList, UserData);
    setEdit(false);
    setDeleteCourseList([]);
    // fetchAllUser();
  };
  return (
    <>
      <Box textAlign='right' className={classes.tableContainer}>
        <Box mb={2}>
          {edit ? (
            <>
              <Button
                variant='outlined'
                color='secondary'
                className={classes.btn}
                onClick={handleCancel}
                size='small'
              >
                Cancel
              </Button>
              <Button
                disabled={deleteCourseList.length ? false : true}
                variant='outlined'
                color='secondary'
                onClick={handleDelete}
                size='small'
              >
                Delete
              </Button>
            </>
          ) : (
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setEdit(true)}
              size='small'
            >
              Edit
            </Button>
          )}
        </Box>

        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size='small'
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align='right'>current/total</TableCell>
                <TableCell align='right'>%</TableCell>
                {edit && <TableCell align='right'>delete</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUserData.map((item) => (
                <TableRow key={item.info}>
                  <TableCell component='th' scope='row'>
                    {item.info}
                  </TableCell>
                  <TableCell align='right'>{`${item.currentSlide}/${item.totalSlide}`}</TableCell>
                  <TableCell align='right'>
                    {Math.floor((item.currentSlide / item.totalSlide) * 100)}
                  </TableCell>
                  {edit && (
                    <TableCell align='right'>
                      <IconButton
                        color='secondary'
                        disabled={
                          deleteCourseList.includes(item.info) ? true : false
                        }
                        onClick={() =>
                          setDeleteCourseList((edit) => [...edit, item.info])
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchUserData,
  adminDeleteUserCourse,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInfoTable);
