import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavBottom from "./nav/NavBottom";
// import useShowNavBtm from "../hooks/useShowNavBtm";

const ContainerX = ({ showNavBtm = false, children }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: "100%",
      paddingLeft: 10,
      paddingRight: 10,
    },
    childContainer: {
      overflow: "auto",
      paddingTop: 20,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 30,
      marginBottom: 65,
    },
    footer: {
      position: "fixed",
      bottom: 0,
      width: 400,
      left: "50%",
      transform: "translate(-50%, 0)",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        className={classes.container}
      >
        <Box flex={1} className={classes.childContainer}>
          {children}
        </Box>
        <Box className={classes.footer} display={{ xs: "block", sm: "none" }}>
          {showNavBtm ? <NavBottom /> : null}
        </Box>
      </Box>
    </>
  );
};

export default ContainerX;
