import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../redux/action/userAction";
import { AppBar, Toolbar, Button, Box, Hidden } from "@material-ui/core";
import { navButtonSelection } from "../../redux/action/navAction";
import NavTop from "./NavTop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
    },
  },
  button: {
    color: "white",
  },
  topNav: {
    flexGrow: 1,
    textAlign: "left",
    paddingLeft: 40,
    display: "flex",
    alignItems: "center",
  },
}));

const Nav = ({ user, logOut, navButtonSelection }) => {
  const classes = useStyles();

  return (
    <AppBar position='static'>
      <Toolbar className={classes.toolbar}>
        <Box component='span' className={classes.title}>
          <Typography variant='h6' align='center'>
            ONE IPC
          </Typography>
        </Box>
        <Hidden xsDown>
          <Box className={classes.topNav}>
            <NavTop />
          </Box>
        </Hidden>
        {user.email ? (
          <Button
            variant='contained'
            className={classes.button}
            onClick={() => logOut()}
            component={RouterLink}
            to='/'
            size='small'
            color='secondary'
          >
            Logout
          </Button>
        ) : (
          <Button
            variant='contained'
            className={classes.button}
            component={RouterLink}
            to='/login'
            size='small'
            color='secondary'
            onClick={() => navButtonSelection(null)}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({ user: state.userData });

const mapDispatchToProps = { logOut, navButtonSelection };

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
