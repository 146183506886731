import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CoordInfoTable from "./CoordInfoTable";
import { connect } from "react-redux";
import { fetchCoordinatorUser } from "../../redux/action/coordinatorAction";
import { stringDate } from "../../components/others/Time";
import TitleHeader from "../../components/titleHeader/TitleHeader";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionPanelDetail: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  Accordion: {
    backgroundColor: "whitesmoke",
    marginBottom: 8,
  },
}));

const Coordinator = ({
  fetchCoordinatorUser,
  userData,
  coordinatorUserData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    // fetch for users with CoordID that matche's the searching user's current UID
    fetchCoordinatorUser(userData.userId);
  }, [fetchCoordinatorUser, userData]);

  // find the completed unit with 100% vs the total unit
  const getCompletedUserData = (item) => {
    const objectValue = Object.values(item.covid.unit);
    const totalUnit = objectValue.length;

    let completedUnit = 0;

    objectValue.forEach((item) => {
      if (item.currentSlide === item.totalSlide) {
        return completedUnit++;
      }
    });

    return `${completedUnit}/${totalUnit}`;
  };

  return (
    <>
      <Box mt={5}></Box>
      <TitleHeader title='COORDINATOR' />
      <Box m={2}>
        <Box mb={2}>
          <Typography variant='h6'>
            Coordinator Name: {userData.displayName}
          </Typography>
          <Typography variant='body1'>
            Coordinator Location: {userData.location}
          </Typography>
        </Box>
        <div className={classes.root}>
          {coordinatorUserData.length
            ? coordinatorUserData.map((item, index) => (
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  className={classes.Accordion}
                  key={index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {item.displayName} - {item.email}
                    </Typography>
                    <Typography className={classes.heading}>
                      Completed {getCompletedUserData(item)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.expansionPanelDetail}>
                    <Typography variant='body2'>{item.email}</Typography>
                    <Typography variant='body2'>
                      {stringDate(item.createdAt)}
                    </Typography>
                    <Typography variant='body2'>{item.location}</Typography>
                    <Typography variant='body2'>{item.position}</Typography>
                    <CoordInfoTable
                      filteredUserData={Object.values(item.covid.unit)}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            : null}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    coordinatorUserData: state.coordinatorUserData,
  };
};

const mapDispatchToProps = { fetchCoordinatorUser };

export default connect(mapStateToProps, mapDispatchToProps)(Coordinator);
